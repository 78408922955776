import {RunColorConfig} from '@wandb/weave/common/util/section';
import {useCallback, useMemo} from 'react';

import {CustomRunNames} from '../../../state/views/customRunNames/types';
import {Key} from '../../../util/runTypes';
import {runsLinePlotConfigDefaults} from '../defaults';
import {usePanelConfigContext} from '../PanelConfigContext';
import {useRunsLinePlotContext} from '../RunsLinePlotContext/RunsLinePlotContext';
import {usePanelGroupingSettings} from '../RunsLinePlotContext/usePanelGroupingSettings';
import {RunsLinePlotConfig} from '../types';
import {Timestep} from './../../../util/plotHelpers/types';
import {RunSetQuery} from './../../../util/queryTypes';
import {LineConfig} from './types';
// import {useDerivedLinesConfigDebugging} from './useDerivedLinesConfigDebugging';

/**
 * Changing values in the grouping tab in a panel runs line plot
 * 1. Toggling the `Runs` switch -> flips the `aggregate` value
 * 2. Changing the `Group By` option ->   changes the `panelGroupKeys` or `groupBy`
 *  - `groupBy` is the property on the config object, panelGroupKeys is a custom naming convention set in the outliers wrapper
 * 3. Changing the `Agg` boxes -> changes the `groupLine` setting
 * 4. Changing the `Range` boxes -> changes the `groupArea` setting
 * 5. Toggling the `Metrics` switch -> changes the `aggregateMetrics` setting
 */

export const useDerivedLinesConfiguration = ({
  customRunColors,
  customRunNames,
  runsLinePlotConfig,
  runSets,
  zoomTimestep,
}: {
  customRunColors: RunColorConfig | undefined;
  customRunNames?: CustomRunNames;
  runsLinePlotConfig: RunsLinePlotConfig;
  runSets: RunSetQuery[];
  zoomTimestep: Timestep | null;
}): LineConfig => {
  const {
    aggregateCalculations,
    getDefaultLegendTemplateByRunsetId,
    entityName,
    excludeOutliers,
    isSingleRun,
    limit,
    parsedExpressions,
    projectName,
    smoothingType,
    smoothingWeight,
    useRunsTableGroupingInPanels,
    xAxis,
    yLogScale,
    xAxisFormat,
  } = usePanelConfigContext();
  const {aggregatePanelRuns, aggregatePanelMetrics, getGroupKeysByRunsetId} =
    usePanelGroupingSettings();

  const {windowing} = useRunsLinePlotContext();

  const aggregateMetrics =
    aggregatePanelMetrics ?? runsLinePlotConfigDefaults.aggregateMetrics;

  const getLegendTemplateByRunsetId = useCallback(
    (runsetId: string) =>
      isSingleRun
        ? getDefaultLegendTemplateByRunsetId(runsetId)
        : runsLinePlotConfig.legendTemplate != null
        ? runsLinePlotConfig.legendTemplate!
        : getDefaultLegendTemplateByRunsetId(runsetId),
    [
      getDefaultLegendTemplateByRunsetId,
      runsLinePlotConfig.legendTemplate,
      isSingleRun,
    ]
  );

  const newResult = useMemo(() => {
    return {
      /**
       * runsLinePlotConfig settings - the nullish coalescing operator is used as most of these values won't be explicitly set in a panel unless a user modifies the value which then exposes it to Redux and the external data stores.
       */
      aggregateCalculations,
      aggregateMetrics,
      aggregatePanelRuns,
      colorEachMetricDifferently:
        runsLinePlotConfig.colorEachMetricDifferently ?? isSingleRun,
      excludeOutliers,
      groupArea:
        runsLinePlotConfig.groupArea ?? runsLinePlotConfigDefaults.groupArea,
      groupLine:
        runsLinePlotConfig.groupAgg ?? runsLinePlotConfigDefaults.groupAgg,
      legendFields: runsLinePlotConfig.legendFields,
      getLegendTemplateByRunsetId,
      groupKeysByRunsetId: runSets.reduce((acc, runset) => {
        acc[runset.id] = getGroupKeysByRunsetId(runset.id);
        return acc;
      }, {} as Record<string, Key[]>),
      limit,
      plotType:
        runsLinePlotConfig.plotType ?? runsLinePlotConfigDefaults.plotType,
      rootUrl:
        entityName != null && projectName != null
          ? `/${entityName}/${projectName}/runs`
          : null,
      showOriginalAfterSmoothing:
        runsLinePlotConfig.showOriginalAfterSmoothing ??
        runsLinePlotConfigDefaults.showOriginalAfterSmoothing,
      smoothingParam: smoothingWeight,
      smoothingType,
      useRunsTableGroupingInPanels,
      windowing,
      xAxis,
      xAxisFormat,
      yAxis: runsLinePlotConfig.metrics ?? runsLinePlotConfigDefaults.metrics,
      yLogScale,

      /**
       * `data` refers to high level information that's stable throughout the life of a panel
       */
      entityName,
      projectName,

      /**
       * run color and display name overrides. comes from `section` part
       * of view spec, meaning it's scoped to a workspace or panel grid
       */
      customRunColors,
      customRunNames,

      /**
       * other data from ??? sources
       * TODO: figure out where this data comes from and explain it
       */
      expressions: parsedExpressions.expressions ?? [],
      // Only pass in the part of runSets that we need. getLinesFromData memoizes
      // with deep equal.
      runSets,
      singleRun: isSingleRun,
      xExpression: parsedExpressions.xExpression,
      zoomTimestep,
    };
  }, [
    aggregateCalculations,
    aggregateMetrics,
    aggregatePanelRuns,
    customRunColors,
    customRunNames,
    getGroupKeysByRunsetId,
    getLegendTemplateByRunsetId,
    entityName,
    excludeOutliers,
    isSingleRun,
    limit,
    parsedExpressions.expressions,
    parsedExpressions.xExpression,
    projectName,
    runSets,
    runsLinePlotConfig.colorEachMetricDifferently,
    runsLinePlotConfig.groupAgg,
    runsLinePlotConfig.groupArea,
    runsLinePlotConfig.legendFields,
    runsLinePlotConfig.metrics,
    runsLinePlotConfig.plotType,
    runsLinePlotConfig.showOriginalAfterSmoothing,
    smoothingType,
    smoothingWeight,
    useRunsTableGroupingInPanels,
    windowing,
    xAxis,
    xAxisFormat,
    yLogScale,
    zoomTimestep,
  ]);

  // useDerivedLinesConfigDebugging(newResult, true);

  return newResult;
};
